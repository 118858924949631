/* The CSS code `html { scroll-behavior: smooth; }` is setting the scroll behavior for the HTML element
to smooth. This means that when a user clicks on a link that navigates to a different section of the
page, the scrolling will be animated and smooth rather than instant. */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
